import React from "react";
import { Box, IconButton, useColorModeValue } from "@chakra-ui/react";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import TestimonialCard from "components/card/TestimonialCard";

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Testimonial({ testimonials = [] }) {
  const [slider, setSlider] = React.useState(0);

  const textColorPrimary = useColorModeValue("white", "white");
  const btnColorPrimary = useColorModeValue("blackAlpha", "whiteAlpha");
  const handleActiveSlide = (index) => {
    // If you want to add any custom action on active slide change,
    // you can add it here
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    // On before slide change, set z-index of active slide to 1 and
    // z-index of next slide to 2
    const slideElements = document.querySelectorAll(".slick-slide");
    slideElements[oldIndex].style.zIndex = 1;
    slideElements[newIndex].style.zIndex = 2;
    handleActiveSlide(newIndex);
  };
  return (
    <Box position={"relative"} width={"full"} overflow={"hidden"} mt="2rem">
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <IconButton
        display={{ sm: "none", md: "inline-flex" }}
        aria-label="left-arrow"
        colorScheme={btnColorPrimary}
        borderRadius="full"
        position="absolute"
        transform={"translate(0%, -50%)"}
        left="0px"
        top="50%"
        zIndex={2}
        onClick={() => slider?.slickPrev()}
      >
        <BsChevronLeft color={textColorPrimary} />
      </IconButton>
      <IconButton
        display={{ sm: "none", md: "inline-flex" }}
        aria-label="right-arrow"
        colorScheme={btnColorPrimary}
        borderRadius="full"
        transform={"translate(0%, -50%)"}
        position="absolute"
        right="0px"
        top="50%"
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BsChevronRight color={textColorPrimary} />
      </IconButton>
      <Slider
        {...settings}
        className="my-slider"
        ref={(slider) => setSlider(slider)}
        beforeChange={handleBeforeChange}
      >
        {testimonials.map((testimonial, index) => (
          <Box key={index} px={{ sm: "10px", md: "3rem" }}>
            <TestimonialCard {...testimonial} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
