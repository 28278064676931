// Chakra imports
import { Flex, Icon, Text, Button, useColorModeValue } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
function NavItem({ icon = null, title, link = "/home/home", active, onClick }) {
  const textColor = useColorModeValue("#7551ff", "secondaryGray.600");
  return (
    <NavLink
      from="/"
      to={link}
      w="100%"
      style={() => ({
        width: "fit-content",
        // marginTop: "40px",
        // backgroundColor: "#000",
      })}
      variant="primary"
    >
      <Flex
        align="center"
        m={"0.5rem"}
        pt={{ lg: "0px", xl: "0px" }}
        w="fit-content"
      >
        <Button
          w="fit-content"
          // px="10"
          borderRadius="100px"
          style={active === title ? { backgroundColor: "#7551ff" } : null}
          variant={active === title ? "darkBrand" : "ghost"}
          onClick={onClick}

          leftIcon={icon ? (
            <Icon
              as={icon}
              fontSize="md"
              // h="13px"
              // w="8px"
              color={active === title ? "white" : textColor}
            />
          ) : null}
        >
          <Text fontWeight={300} color={active === title ? "white" : textColor}>
            {title}
          </Text>
        </Button>
      </Flex>
    </NavLink>
  );
}

export default NavItem;
