// Chakra imports
import { Button, Grid, Flex, Text, useColorModeValue, Badge } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Carousel from "components/carousel/Carousel";
import { useHistory } from "react-router-dom";
// Assets
export default function CollegeDetailCard(props) {
  const navigate = useHistory();
  const { states, mapLinks, data_aos, data_aos_delay, data_aos_duration } =
    props;
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Card
      p="20px"
      mb="2rem"
      data-aos={data_aos}
      data-aos-delay={data_aos_delay}
      data-aos-duration={data_aos_duration}
    >
      <Grid gap="1rem" templateColumns={{ sm: "1fr", md: "1fr 1fr" }}>
        <Flex direction="column">
          <Text
            color={textColor}
            fontSize={{
              base: "lg",
              md: "30",
              lg: "30",
              xl: "30",
              "2xl": "30",
              "3xl": "30",
            }}
            mb="5px"
            fontWeight="bold"
            me="14px"
          >
            Exploring Top Medical Colleges{"\t"}
            <Badge colorScheme="green">FREE</Badge>
          </Text>
          <Text >We are here to provide you with comprehensive details of colleges, including their cutoff scores, tuition fees etc and yearly updates about the colleges externally, enabling you to make informed comparisons between them and ultimately choose the best college for you based on your score.</Text>

          <Button
            fontWeight="500"
            borderRadius="70px"
            colorScheme="teal"
            size="lg"
            px="24px"
            py="5px"
            mt="auto"
            onClick={() => navigate.push('/home/states')}
          >
            Explore
          </Button>
        </Flex>
        <Carousel cards={states} mapLinks={mapLinks} color="teal" />
      </Grid>
    </Card>
  );
}
