import {
  Box,
  Button,
  CloseButton,
  Flex,
  Grid,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import InfoCard from "components/card/InfoCard";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { TbScale } from "react-icons/tb";

const Compare = ({ items = [], setItems = () => {}, isActive = false }) => {
  const handleDelete = (keyToDelete) => {
    const newArray = items.filter((val, key) => key !== keyToDelete);
    setItems(newArray);
  };
  const [maxi, setMaxi] = useState({});
  const [mini, setMini] = useState({});
  const modalColor = useColorModeValue("secondaryGray.300", "navy.800");
  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "0px 18px 40px rgba(0, 0, 0, 0.12)"
  );
  const maxFields = ["Total Seats", "Campus size"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const placeholders = Array.from(
    { length: 3 - items.length > 0 ? 3 - items.length : 0 },
    (_, i) => (
      <Button
        minH={{ sm: "unset", md: "60vh" }}
        h="100%"
        w="100%"
        py="1rem"
        px="6rem"
        onClick={onClose}
        size="lg"
        leftIcon={<MdAdd />}
      >
        Add
      </Button>
    )
  );

  return (
    <Box zIndex="99" position="fixed" top="10vh" left="10vh">
      {isActive && (
        <>
          <Button
            data-aos="fade-down"
            data-aos-duration="1000"
            display={{ sm: "none", md: "flex" }}
            bg={bgButton}
            color="white"
            zIndex="99"
            position="fixed"
            variant="no-effects"
            left={""}
            right={"5%"}
            top="30px"
            border="1px solid"
            borderColor="#6A53FF"
            borderRadius="50px"
            align="center"
            justify="center"
            size="lg"
            fontWeight="normal"
            leftIcon={<TbScale />}
            onClick={onOpen}
          >
            Compare
          </Button>
          <IconButton
            display={{ sm: "inline-flex", md: "none" }}
            bg={bgButton}
            color="white"
            zIndex="99"
            position="fixed"
            variant="no-effects"
            left={""}
            right={"5%"}
            top="30px"
            border="1px solid"
            borderColor="#6A53FF"
            borderRadius="50px"
            align="center"
            justify="center"
            size="lg"
            icon={<TbScale />}
            onClick={onOpen}
            data-aos="fade-down"
            data-aos-duration="1000"
          />
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalHeader>Compare Colleges</ModalHeader>
          <ModalCloseButton color="red.400" />
          <ModalBody>
            <Grid
              mx="3rem"
              templateColumns={{ sm: "1fr", md: "1fr 1fr 1fr" }}
              gap="1rem"
            >
              {items.map((item, i) => (
                <Flex direction="column">
                  <Flex mx="auto" direction="row" my="1rem">
                    <Text my="auto">{item.title.slice(0, 30) + "..."}</Text>
                    <CloseButton
                      color="red.400"
                      onClick={() => handleDelete(i)}
                    />
                  </Flex>
                  <SimpleGrid key={i} columns="1" gap="20px">
                    {item.hasOwnProperty("information") &&
                      Object.keys(item.information).map((info, index) => {
                        if (!isNaN(item.information[info])) {
                          let num = Number(item.information[info]);
                          if (!maxi.hasOwnProperty(info) || maxi[info] < num) {
                            setMaxi((prev) => ({ ...prev, [info]: num }));
                          }
                          if (!mini.hasOwnProperty(info) || mini[info] > num) {
                            setMini((prev) => ({ ...prev, [info]: num }));
                          }
                        }
                        return (
                          <InfoCard
                            key={index}
                            boxShadow={
                              maxFields.includes(info)
                                ? maxi?.[info] ===
                                  Number(item.information[info])
                                  ? cardShadow + ",0px 0px 10px #7551ff"
                                  : cardShadow
                                : mini?.[info] ===
                                  Number(item.information[info])
                                ? cardShadow + ",0px 0px 10px #7551ff"
                                : cardShadow
                            }
                            title={info}
                            value={item.information[info]}
                          />
                        );
                      })}
                  </SimpleGrid>
                </Flex>
              ))}
              {placeholders}
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                setItems([]);
                onClose();
              }}
              size="lg"
              mr="1rem"
              colorScheme="red"
            >
              Clear
            </Button>
            <Button onClick={onClose} size="lg" leftIcon={<MdAdd />}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Compare;
