/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState } from "react";
import {
  Flex,
  Box,
  Text,
  SimpleGrid,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Heading,
  Link,
  Center,
  AspectRatio,
  useToast,
  Grid,
} from "@chakra-ui/react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

// Chakra imports
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import "aos/dist/aos.css";
import { sendData } from "utils/firebase";
// Chakra imports
// Custom components

import Nft2 from "assets/img/nfts/Nft2.png";
// Custom components

// Custom components
import DefaultHome from "layouts/home/Default";
// Assets
import illustration from "assets/img/auth/auth.png";

function PlanB() {
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [score, setScore] = useState("");
  const [phone, setPhone] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [plan, setPlan] = useState("B");
  const [loading, setLoading] = useState(false);
  const handleSubmitPlan = (e) => {
    e.preventDefault();
    setLoading(true);
    let uid = uuidv4();
    sendData("plans", uid, {
      id: uid,
      name: name,
      email: email,
      phone: phone,
      score: score,
      altPhone: altPhone,
      plan: plan,
    }).then(() => {
      setName("");
      setEmail("");
      setScore("");
      setPhone("");
      setAltPhone("");
      setPlan(null);

      setLoading(false);
      toast({
        title: "Appointment Booked.",
        description: "Our team will contact you",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };
  // Chakra color mode
  const planB = {
    title: "Plan B",
    price: "Get Now",
    embed: "https://www.youtube.com/embed/H6JUK8ED32g?controls=0",
    pic: Nft2,
    points: [
      {
        content: "College search and selection",
        positive: true,
      },
      {
        content: "Application support",
        positive: true,
      },
      {
        content: "Testing support",
        positive: true,
      },
      {
        content: "Financial aid and scholarship advice",
        positive: false,
      },
      {
        content: "Decision making",
        positive: false,
      },
    ],
  };
  return (
    <DefaultHome illustrationBackground={illustration} image={illustration}>
      <Flex id="top" w="100%" mx="auto" justifyContent="start" direction="row">
        <Center
          h="100vh"
          w={{ sm: "100%", md: "50%", lg: "50%", xl: "50%" }}
          justifyContent="start"
          direction="row"
        >
          <Box data-aos="fade-right" data-aos-duration="1000">
            <Heading
              fontSize={{ sm: "40", md: "50", lg: "60", xl: "70" }}
              fontWeight={700}
              color="secondaryGray.600"
            >
              {planB.title}
            </Heading>
            <Text fontSize="20" fontWeight={300} color="white.600">
              Welcome to our college counseling website!
            </Text>
            <Button
              onClick={() => {
                const section = document.getElementById("about");
                section.scrollIntoView({ behavior: "smooth" });
              }}
              w="fit-content"
              px="10"
              mt="5"
              variant="action"
            >
              See all
            </Button>
          </Box>
        </Center>
        <Center
          w={{ sm: "0%", md: "50%", lg: "50%", xl: "50%" }}
          justifyContent="end"
          direction="row"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <AspectRatio
            ratio={1.5}
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "18rem", "3xl": "18rem" }}
            borderRadius="20px"
          >
            <iframe
              style={{ borderRadius: "20px", width: "100%", height: "100%" }}
              width="100%"
              height="100%"
              src={planB.embed}
              title={planB.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </AspectRatio>
        </Center>
      </Flex>
      <Box id="about" min="100vh" mt="4rem">
        <Card data-aos="fade-up" data-aos-duration="1000">
          <Grid templateColumns="16fr 1fr">
            <Text fontSize="20" fontWeight={300} mb="1rem" color="white.600">
              Book an appointment with accidental doctor team
            </Text>
            <Link target="_blank" href="https://wa.me/7078799044">
              <AiOutlineWhatsApp ml="auto" float="right" size="2rem" />
            </Link>
          </Grid>
          <form onSubmit={(e) => handleSubmitPlan(e)}>
            <Flex w="100%" direction="column">
              <SimpleGrid columns={{ base: 1, md: 2 }} gap="30px">
                <InputField
                  label="Name"
                  value={name}
                  setValue={setName}
                  type="text"
                  placeholder="John Doe"
                  borderRadius="30px"
                  required
                />
                <InputField
                  label="Email"
                  type="email"
                  value={email}
                  setValue={setEmail}
                  placeholder="example@company.com"
                  borderRadius="30px"
                  required
                />
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 3 }} gap="30px">
                <InputField
                  label="NEET Score"
                  max={720}
                  value={score}
                  setValue={setScore}
                  type="number"
                  placeholder="100"
                  borderRadius="30px"
                />
                <InputField
                  label="Phone Number"
                  value={phone}
                  setValue={setPhone}
                  type="number"
                  placeholder="+91-XXX-XXX-XXXX"
                  borderRadius="30px"
                  required
                />
                <InputField
                  label="Alt. Phone Number"
                  value={altPhone}
                  setValue={setAltPhone}
                  type="number"
                  placeholder="+91-XXX-XXX-XXXX"
                  borderRadius="30px"
                />
              </SimpleGrid>
              <RadioGroup defaultValue="B" required>
                <Stack spacing={5} direction="row">
                  <Radio
                    onClick={() => {
                      setPlan("B");
                    }}
                    value="B"
                  >
                    Plan B
                  </Radio>
                  <Radio
                    onClick={() => {
                      setPlan("C");
                    }}
                    value="C"
                  >
                    Plan C
                  </Radio>
                </Stack>
              </RadioGroup>
            </Flex>
            <Button
              isLoading={loading}
              variant="darkBrand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              mt="1rem"
              type="submit"
            >
              Apply
            </Button>
          </form>
        </Card>
      </Box>
    </DefaultHome>
  );
}

export default PlanB;
