/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import AOS from "aos";
import illustration from "assets/img/auth/auth.png";
import Logo from "assets/img/logo-moving.gif";
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Card from "components/card/Card";
import CollegeDetailCard from "components/card/CollegeDetailCard";
import CountCard from "components/card/CountCard";
import LongPlanCard from "components/card/LongPlanCard";
import InputField from "components/fields/InputField";
import Testimonial from "components/testimonial/Testimonial";
import DefaultHome from "layouts/home/Default";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import {
  getDataRealtime,
  getDataRealtimeInArray,
  getKeysRealtimeInArray,
  sendData,
} from "utils/firebase";
import { v4 as uuidv4 } from "uuid";
import "./style.css";
function Home() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [state, setState] = useState([]);

  const [testimonials, setTestimonials] = useState([]);
  const [mapLinks, setMapLinks] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDataRealtime("States", "", setMapLinks);
    getKeysRealtimeInArray("colleges", "", setState);
    getDataRealtimeInArray("Testimonials", "", setTestimonials);

    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  // Chakra color mode
  const toast = useToast();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const plans = [
    {
      title: "Plan A",
      embed: "https://www.youtube.com/embed/2VKpFSYhGWY?rel=0",
      price: "Get Now",
      download:
        "https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/pdf%2FPlan%20A-%20Brochure.pdf?alt=media&token=b9258625-9a31-414a-acf6-1affb48dc1a3",
      link: "#/home/plan-a",
      data_aos: "fade-up",
      pic: Nft1,
      points: [
        {
          content: "Internal details state wise",
          positive: true,
        },
        {
          content: "You can buy here state wise internal details",
          positive: true,
        },
        {
          content:
            "Data directly taken from the medico, studying in the same college",
          positive: true,
        },
        {
          content: "It will help you to choose the right college",
          positive: true,
        },
        {
          content: "Only for private available now",
          positive: true,
        },
      ],
    },
    {
      title: "Plan B",
      embed: "https://www.youtube.com/embed/H6JUK8ED32g?controls=0&rel=0",
      price: "Book Appointment",
      // link: "#/home/plan-b",
      download:
        "https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/pdf%2FPlan%20B-Brochure.pdf?alt=media&token=93be81ef-80a4-40fd-8a13-51d4ac44dc1d",
      data_aos: "fade-up",
      pic: Nft2,
      points: [
        {
          content: "It is specially for UP only",
          positive: true,
        },
        {
          content: "In this we will do your counselling process",
          positive: true,
        },
        {
          content:
            "Counselling is done on the basis of internal details of colleges",
          positive: true,
        },
        {
          content:
            "We have proudly admitted maximum students in 21, 22 batches of UP",
          positive: true,
        },
        {
          content: "We have extra expertise in UP",
          positive: true,
        },
        {
          content: "Our strong connection will help you reach out best college",
          positive: true,
        },
        {
          content: "Both private and government colleges available",
          positive: true,
        },
      ],
    },
    {
      title: "Plan C",
      embed: "https://www.youtube.com/embed/cuwqec_q99s?controls=0&rel=0",
      price: "Book Appointment",
      // link: "#/home/plan-c",
      download:
        "https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/pdf%2FPlan%20C-%20Brochure.pdf?alt=media&token=ed9de48d-af45-42fc-9784-9f29b594e67b",
      data_aos: "fade-up",
      pic: Nft3,
      points: [
        {
          content: "In this we will do your whole counselling process",
          positive: true,
        },
        {
          content:
            "Include all india counselling (deemed) and all states of india",
          positive: true,
        },
        {
          content:
            "Same counselling is done on the basis of internal and external features",
          positive: true,
        },
        {
          content: "Both private and government colleges available",
          positive: true,
        },
        {
          content: "Proudly admitted many students around India",
          positive: true,
        },
      ],
    },
  ];
  const handleSubmitMsg = (e) => {
    e.preventDefault();
    let uid = uuidv4();
    sendData("contact", uid, {
      id: uid,
      name: name,
      phone: phone,
      msg: msg,
    }).then(() => {
      // console.log("Data sent successfully!");
      setName("");
      setPhone("");
      setMsg("");
      toast({
        title: "Message Sent.",
        description: "Our team will contact you",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };
  return (
    <DefaultHome illustrationBackground={illustration} image={illustration}>
      {isLoading ? (
        <Flex
          w="100%"
          h="100vh"
          direction="column"
          justify={"center"}
          align="center"
        >
          <Spinner color={textColorPrimary} />
        </Flex>
      ) : (
        <>
          <Flex
            h="100vh"
            id="top"
            w="100%"
            mx="auto"
            justifyContent="space-between"
            direction={{ sm: "column-reverse", md: "row" }}
          >
            <Center
              h="100vh"
              w={{ sm: "100%", md: "50%", lg: "50%", xl: "50%" }}
              justifyContent="start"
              direction="row"
            >
              <Box data-aos="fade-right" data-aos-duration="1000">
                <Heading
                  fontSize={{ sm: "40", md: "50", lg: "60", xl: "70" }}
                  fontWeight={700}
                  color="secondaryGray.600"
                >
                  Hello!
                </Heading>
                <Text fontSize="20" fontWeight={300} color="white.600">
                  Welcome to Accidental Doctor - your ultimate MBBS guide!
                  Revolutionize your college search with Accidental Doctor's
                  in-depth video content. Enroll now to secure admission and
                  become a successful doctor with insider insights from current
                  med students.
                </Text>
              </Box>
            </Center>
            <Center
              // w={{ sm: "0%", md: "50%", lg: "50%", xl: "50%" }}
              justifyContent={{ sm: "center", md: "right" }}
              direction="row"
              // visibility={{ sm: "hidden", md: "visible", lg: "visible" }}
              // data-aos={{sm:"fade-up",md:"fade-left"}}
              // data-aos-offset="100"
              // data-aos-duration="1000"
            >
              <Image
                boxSize={{ sm: "50vh", md: "60vh" }}
                src={Logo}
                alt="Accidental Doctor"
              />
            </Center>
          </Flex>
          <CountCard client={600} college={80} student={8} />
          <CollegeDetailCard
            states={state}
            mapLinks={mapLinks}
            data_aos="fade-up"
            data_aos_delay="200"
            data_aos_duration="1000"
          />
          <Grid id="plans" templateColumns="1fr" gap="2rem">
            <LongPlanCard
              embed={plans[0].embed}
              name={plans[0].title}
              points={plans[0].points}
              image={plans[0].pic}
              currentbid={plans[0].price}
              download={plans[0].download}
              link={plans[0].link}
              data_aos={plans[0].data_aos}
            />
            <LongPlanCard
              name={plans[1].title}
              embed={plans[1].embed}
              points={plans[1].points}
              image={plans[1].pic}
              currentbid={plans[1].price}
              download={plans[1].download}
              data_aos={plans[1].data_aos}
              order
            />
            <LongPlanCard
              name={plans[2].title}
              embed={plans[2].embed}
              points={plans[2].points}
              image={plans[2].pic}
              currentbid={plans[2].price}
              download={plans[2].download}
              data_aos={plans[2].data_aos}
            />
          </Grid>
          <Grid id="testimonials" templateColumns="1fr" gap="2rem">
            <Testimonial testimonials={testimonials} />
          </Grid>
          <Box
            id="contact"
            mt="2rem"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <Card w="100%">
              <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr" }} gap="1rem">
                <Flex direction="column" gap="1rem">
                  <Text
                    fontSize="20"
                    fontWeight={300}
                    mb="1rem"
                    color="white.600"
                  >
                    Talk to us
                  </Text>
                  <Box maxW="100%" h="30vh" pos="relative" overflow="hidden">
                    <Box
                      as="iframe"
                      w="100%"
                      h="100%"
                      borderRadius="20px"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1773.2320281948907!2d83.06853996256193!3d27.267333299999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3996db2573113c09%3A0xdcbfde46094ac121!2sBasdiliya!5e0!3m2!1sen!2sin!4v1681369410466!5m2!1sen!2sin"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </Box>
                  <Flex maxW="100%">
                    <Icon fontSize="xl" as={FaMapMarkerAlt} mr={2} />
                    <Text ml={2}>
                      Basdiliya road Madhukarpur Siddharth Nagar (UP), 272207
                    </Text>
                  </Flex>
                  <Flex maxW="100%">
                    <Icon fontSize="xl" as={FaPhone} mr={2} />
                    <Text ml={2}>+91-7078799044</Text>
                  </Flex>
                  <Flex maxW="100%">
                    <Icon fontSize="xl" as={FaEnvelope} mr={2} />
                    <Text ml={2}>accidentaldoctorr@gmail.com</Text>
                  </Flex>
                </Flex>

                <form onSubmit={(e) => handleSubmitMsg(e)}>
                  <Flex w="100%" direction="column">
                    <InputField
                      label="Name"
                      type="text"
                      value={name}
                      setValue={setName}
                      placeholder="John Doe"
                      borderRadius="30px"
                      required
                    />
                    <InputField
                      label="Phone Number"
                      value={phone}
                      setValue={setPhone}
                      type="number"
                      placeholder="+91-XXX-XXX-XXXX"
                      borderRadius="30px"
                      required
                    />
                    <InputField
                      label="Message"
                      value={msg}
                      setValue={setMsg}
                      placeholder="Type your message here..."
                      type="textarea"
                      me="10px"
                      borderRadius="16px"
                      required
                    />
                  </Flex>

                  <Button
                    rightIcon={<FiSend />}
                    w="100%"
                    variant="darkBrand"
                    color="white"
                    size="lg"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    type="submit"
                  >
                    Send Message
                  </Button>
                </form>
              </Grid>
            </Card>
          </Box>
        </>
      )}
    </DefaultHome>
  );
}

export default Home;
