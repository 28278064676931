import {
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { useCallback, useEffect, useState } from "react";
import { CgShoppingCart } from "react-icons/cg";
import { getDataRealtime, sendData } from "utils/firebase";
import { v4 as uuidv4 } from "uuid";

const Cart = ({ deleteFromCart, items, emptyCart }) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const modalColor = useColorModeValue("white", "navy.700");
  const [open, setOpen] = useState(false);
  const [prices, setPrices] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [total, setTotal] = useState(0);
  const number = items.length;
  const cartItems = items;
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenWarn,
    onOpen: onOpenWarn,
    onClose: onCloseWarn,
  } = useDisclosure();
  useEffect(() => {
    getDataRealtime("Prices", "", setPrices);
  }, []);
  useEffect(() => {
    if (JSON.stringify(items) === "{}") handleClose();
    let sum = 0;
    cartItems.forEach((item) => {
      sum += prices.hasOwnProperty(item) ? Number(prices[item]) : 0;
    });
    setTotal(sum);
  }, [items]);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const createPaymentLink = (id, items) => {
    // console.log(`ID for link:${id}`);
    onOpenWarn();
    const url = process.env.REACT_APP_PAY_API;
    const data = {
      amount: total * 100,
      currency: "INR",
      accept_partial: false,
      description: "Plan A",
      customer: {
        name: name,
        email: email,
        contact: phone,
      },
      notify: {
        sms: true,
        email: true,
      },
      reminder_enable: true,
      notes: {
        states: items.join(", "),
        id: id,
      },
      callback_url: "https://accidentaldoctor.in/#/home/order/",
      callback_method: "get",
    };
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        window.open(data?.message, "_self");
      })
      .catch((error) => {
        onCloseWarn();
        console.error(error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const uid = uuidv4();

    // console.log(`ID for send:${uid}`);
    sendData("plans", uid, {
      id: uid,
      name: name,
      email: email,
      phone: phone,
      total: total,
      items: items,
      status: "initiated",
      plan: "A",
    }).then(() => {
      setName("");
      setEmail("");
      setPhone("");
      emptyCart();
      // console.log(`ID for then:${uid}`);
      toast({
        title: "Please pay",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
    console.log(process.env.REACT_APP_ISPAY);
    if (false || process.env.REACT_APP_ISPAY === "yes") {
      createPaymentLink(uid, items);
    } else {
      toast({
        title: "Our team will contact you",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    onClose();
  };
  return (
    <Box zIndex="99" position="fixed" top="10vh" left="10vh">
      {number > 0 && (
        <Button
          bg={bgButton}
          zIndex="99"
          position="fixed"
          variant="no-effects"
          left={""}
          right={"5%"}
          color="white"
          top="30px"
          border="1px solid"
          borderColor="#6A53FF"
          fontWeight="normal"
          borderRadius="50px"
          display="flex"
          align="center"
          justify="center"
          size="lg"
          leftIcon={<CgShoppingCart color="white" />}
          data-aos="fade-down"
          data-aos-duration="1000"
          onClick={handleOpen}
        >
          <span
            style={{
              transform: "translate(2.3rem,-1rem)",
              position: "absolute",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "1rem",
              height: "1rem",
              fontSize: "65%",
              color: "#7551ff",
              paddingTop: "0.15rem",
            }}
          >
            {number}
          </span>
          CART
        </Button>
      )}
      <Modal isOpen={isOpenWarn} onClose={onCloseWarn} size="full">
        <ModalOverlay />
        <ModalContent textAlign="center" py="10vh" bgColor={modalColor}>
          <Spinner mt="5" mx="auto" size="xl" color={textColorPrimary} />
          <ModalHeader fontSize="3xl">Payment in progress</ModalHeader>
          <ModalBody>
            This page will be redirected soon. Please do not reload or close the
            page.
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalHeader>Enter Details</ModalHeader>
          <ModalCloseButton color="red.400" />
          <form onSubmit={(e) => handleSubmit(e)}>
            <ModalBody>
              <InputField
                label="Name"
                value={name}
                type="text"
                setValue={setName}
                placeholder="John Doe"
                borderRadius="30px"
                required
              />
              <InputField
                label="Email"
                type="email"
                value={email}
                setValue={setEmail}
                placeholder="example@company.com"
                borderRadius="30px"
                required
              />
              <InputField
                label="Phone Number"
                value={phone}
                setValue={setPhone}
                type="number"
                placeholder="+91-XXX-XXX-XXXX"
                borderRadius="30px"
                required
              />
            </ModalBody>

            <ModalFooter>
              <Text fontSize="2xl" mr="auto">
                Total Price: ₹{total}
              </Text>
              <Button type="submit" size="lg">
                Pay
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={open}
        onClose={handleClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalHeader>Cart</ModalHeader>
          <ModalCloseButton color="red.400" />
          <ModalBody>
            {items.map((item, i) => {
              return (
                <>
                  <Flex>
                    <Text py="0.4rem" key={i}>
                      {item}
                    </Text>
                    <Text py="0.4rem" ml="auto" key={i}>
                      {prices.hasOwnProperty(item) ? prices[item] : 0}
                    </Text>
                    <CloseButton
                      onClick={() => deleteFromCart(item)}
                      color="red.400"
                    />
                  </Flex>
                </>
              );
            })}
          </ModalBody>

          <ModalFooter>
            <Text fontSize="2xl" mr="auto">
              Total Price: ₹{total}
            </Text>
            <Button
              onClick={() => {
                onOpen();
                handleClose();
              }}
              size="lg"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Cart;
