import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPerson,
} from "react-icons/md";

// Admin Imports
import DataTables from "views/admin/dataTables";
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Home from "views/home";
import Paid from "views/home/paid";
import CollegeMain from "views/plans/college-detail";
import Colleges from "views/plans/college-detail/college";
import CollegeDetail from "views/plans/college-detail/college-detail";
import PlanA from "views/plans/plan-a";
import PlanB from "views/plans/plan-b";
import PlanC from "views/plans/plan-c";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/data-tables",
    component: DataTables,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Order",
    layout: "/home",
    path: "/order",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Paid,
  },
  {
    name: "Home",
    layout: "/home",
    path: "/home",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Home,
  },
  {
    name: "College Details",
    layout: "/home",
    path: "/states",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: CollegeMain,
  },
  {
    name: "Plan A",
    layout: "/home",
    path: "/plan-a",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PlanA,
  },
  {
    name: "Colleges",
    layout: "/home",
    path: "/colleges/:state",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Colleges,
  },
  {
    name: "College Detail",
    layout: "/home",
    path: "/college/:state/:id",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: CollegeDetail,
  },
  {
    name: "Plan B",
    layout: "/home",
    path: "/plan-b",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PlanB,
  },
  {
    name: "Plan C",
    layout: "/home",
    path: "/plan-c",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PlanC,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
];

export default routes;
