import { useState, useEffect, useRef } from "react";
import { Grid, GridItem, Center, Text, Flex } from "@chakra-ui/react";
import { FiUsers } from "react-icons/fi";
import { FaUniversity } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";

const CountCard = ({ client = 0, college = 0, student = 0 }) => {
  const [clientCount, setClientCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [collegeCount, setCollegeCount] = useState(0);
  const elementRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        const increment = Math.floor(client / 70); // Increase count gradually
        const increment1 = Math.floor(college / 70);
        const increment2 = Math.floor(student / 2);
        let newCount = clientCount;
        let newCount1 = collegeCount;
        let newCount2 = studentCount;
        const interval = setInterval(() => {
          newCount = Math.min(newCount + increment, client);
          newCount1 = Math.min(newCount1 + increment1, college);
          newCount2 = Math.min(newCount2 + increment2, student);
          setClientCount(newCount);
          setCollegeCount(newCount1);
          setStudentCount(newCount2);
          if (
            newCount >= client &&
            newCount1 >= college &&
            newCount2 >= student
          )
            clearInterval(interval);
        }, 20);
        observer.disconnect();
      }
    });
    observer.observe(element);
    observerRef.current = observer;
    return () => {
      observer.disconnect();
      observerRef.current = null;
    };
  }, [client, college, student, clientCount, collegeCount, studentCount]);

  useEffect(() => {
    const observer = observerRef.current;
    if (observer) {
      observer.observe(elementRef.current);
    }
  }, []);

  return (
    <Grid
      ref={elementRef}
      templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
      gap="2rem"
      mb="2rem"
    >
      <GridItem rowSpan={1} colSpan={1}>
        <Center as={Flex} direction="column">
          <Text fontSize="4xl">{clientCount}+</Text>
          <Flex gap="0.5rem" direction="row">
            <Center>
              <BsPerson />
            </Center>
            <Text fontSize="xl" casing="uppercase">
              Clients
            </Text>
          </Flex>
        </Center>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Center as={Flex} direction="column">
          <Text fontSize="4xl">{collegeCount}+</Text>
          <Flex gap="0.5rem" direction="row">
            <Center>
              <FaUniversity />
            </Center>
            <Text fontSize="xl" casing="uppercase">
              Colleges
            </Text>
          </Flex>
        </Center>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Center as={Flex} direction="column">
          <Text fontSize="4xl">{studentCount}</Text>
          <Flex gap="0.5rem" direction="row">
            <Center>
              <FiUsers />
            </Center>
            <Text fontSize="xl" casing="uppercase">
              Team
            </Text>
          </Flex>
        </Center>
      </GridItem>
    </Grid>
  );
};

export default CountCard;
