// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components

export default function Default(props) {
  const {
    setValue = () => {},
    value,
    id,
    label,
    extra,
    placeholder,
    background,
    type,
    mb,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  return (
    <Flex direction="column" mb={mb ? mb : "30px"}>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>
      {type === "textarea" ? (
        <Textarea
          id={id}
          bg={background ? background : inputBg}
          fontWeight="500"
          variant="main"
          {...rest}
          placeholder={placeholder}
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size="sm"
        />
      ) : (
        <Input
          {...rest}
          type={type}
          id={id}
          bg={background ? background : inputBg}
          fontWeight="500"
          variant="main"
          placeholder={placeholder}
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          h="44px"
          maxh="44px"
        />
      )}
    </Flex>
  );
}
