import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { useRef, useState } from "react";
import { BsKey } from "react-icons/bs";
import { MdDone } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getCollection } from "utils/firebase";

const Key = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const modalColor = useColorModeValue("secondaryGray.300", "navy.800");
  const formRef = useRef(null);
  const [key, setKey] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = (e) => {
    e.preventDefault();
    getCollection(
      "plans",
      (entry) => {
        const vals = Object.values(entry);
        if (vals.length > 0) {
          const items = vals[0]?.items;
          if (items.length <= 10) {
            getCollection(
              "internal",
              (data) => {
                dispatch({ type: "stateChange", payload: data });
              },
              ["state", "in", items]
            );
          } else {
            const chunkedItems = [];
            for (let i = 0; i < items.length; i += 10) {
              chunkedItems.push(items.slice(i, i + 10));
            }
            chunkedItems.forEach((chunk) => {
              getCollection(
                "internal",
                (data) => {
                  dispatch({ type: "addChange", payload: data });
                },
                ["state", "in", chunk]
              );
            });
          }

          toast({
            position: "top",
            title: "Key Accepted",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setKey("");
          formRef.current.reset();
          onClose();
        } else {
          toast({
            position: "top",
            title: "Wrong Key",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setKey("");
          formRef.current.reset();
        }
      },
      ["key", "==", key]
    );
  };
  return (
    <Box>
      <Button
        variant="outline"
        size="md"
        fontWeight="medium"
        mr={4}
        _focus={{ boxShadow: "none" }}
        leftIcon={<BsKey />}
        onClick={onOpen}
      >
        Key
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalHeader>Internal Information</ModalHeader>
          <ModalCloseButton color="red.400" />
          <ModalBody>
            <form id="key-form" ref={formRef} onSubmit={(e) => handleSubmit(e)}>
              <InputField
                value={key}
                setValue={setKey}
                type="text"
                placeholder="Enter your key here..."
                borderRadius="30px"
                required
              />
            </form>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" form="key-form" leftIcon={<MdDone />}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Key;
