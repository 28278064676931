import { createStore } from "redux";
const initialState = {
  internal: {},
  isKey: false,
};

function reducer(state = initialState, action) {
  if (action.type === "stateChange") {
    // console.log(action.payload);
    return {
      isKey: Object.keys(action.payload).length !== 0,
      internal: action.payload,
    };
  } else if (action.type === "addChange") {
    // console.log(action.payload);
    return { ...state, internal: { ...state.internal, ...action.payload } };
  }
  return state;
}

export const store = createStore(reducer);
