import { Box, Badge, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { FaQuoteLeft } from "react-icons/fa";

const TestimonialCard = ({ name = "", college = "", body = "" }) => {
  const textColorBody = useColorModeValue("black", "gray.300");
  return (
    <Card minH={{ sm: "60vh", md: "45vh" }}>
      <Box h="100%">
        <Text mr="2" fontSize="2xl" fontWeight="bold" lineHeight="tight">
          {name}
        </Text>
        <Badge borderRadius="full" px="2" colorScheme="blue">
          {college}
        </Badge>
        <Box d="flex" mt="1rem">
          <FaQuoteLeft fontSize="20px" />
          <Text mt="2" ml="2" fontSize="md" color={textColorBody}>
            {body}
          </Text>
        </Box>
      </Box>
    </Card>
  );
};

export default TestimonialCard;
