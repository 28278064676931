// Chakra Imports
import { IconButton, useColorMode } from "@chakra-ui/react";
// Custom Icons
import { IoMdMoon, IoMdSunny } from "react-icons/io";

export default function FixedPlugin(props) {
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  return (
    <IconButton
      {...rest}
      bg={bgButton}
      color="white"
      zIndex="docked"
      position="fixed"
      variant="no-effects"
      right={{ sm: "10px", md: "35px" }}
      bottom={{ sm: "10px", md: "30px" }}
      border="1px solid"
      borderColor="#6A53FF"
      borderRadius="50px"
      align="center"
      justify="center"
      size="lg"
      icon={colorMode === "light" ? <IoMdMoon /> : <IoMdSunny />}
      onClick={toggleColorMode}
    />
  );
}
