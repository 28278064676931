import PropTypes from "prop-types";
import { useEffect } from "react";
// Chakra imports
import { Button, Flex, IconButton, useColorModeValue } from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Footer from "components/footer/FooterAuth";
// Custom components
// Assets
import { MdArrowBack } from "react-icons/md";

import { useHistory, useLocation } from "react-router-dom";

function PlanIllustration(props) {
  const location = useLocation();
  const stateParams = location.pathname.split("/")[2];
  console.log(stateParams);
  const navigate = useHistory();
  const { children } = props;

  // let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );

  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        left="5%"
        mt="30px"
        position="fixed"
        zIndex={123}
        borderRadius="100px"
        bg={navbarBg}
        boxShadow={navbarShadow}
        backdropFilter={navbarBackdrop}
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <Button
          display={{ sm: "none", md: "flex" }}
          bg={stateParams === "states" ? "teal.400" : bgButton}
          color="white"
          zIndex="99"
          variant="no-effects"
          borderRadius="50px"
          align="center"
          justify="center"
          size="lg"
          fontWeight="normal"
          leftIcon={<MdArrowBack />}
          onClick={() => navigate.goBack()}
        >
          Back
        </Button>
        <IconButton
          display={{ sm: "inline-flex", md: "none" }}
          bg={stateParams === "states" ? "teal.400" : bgButton}
          color="white"
          zIndex="99"
          variant="no-effects"
          borderRadius="50px"
          align="center"
          justify="center"
          size="lg"
          icon={<MdArrowBack />}
          onClick={() => navigate.goBack()}
        />
      </Flex>
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        maxW={{ lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ sm: "1rem", md: "20px", lg: "30px", xl: "70px" }}
        // ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}
// PROPS

PlanIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default PlanIllustration;
