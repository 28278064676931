import { useColorModeValue } from "@chakra-ui/color-mode";
import { Button, Flex, Link, Text } from "@chakra-ui/react";
import Key from "components/key/Key";

const FooterAd = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColorSecondary = "gray.400";
  return (
    <Flex
      data-aos="fade-up"
      data-aos-offset="50"
      data-aos-duration="1000"
      data-aos-delay="1500"
      as="FooterAd"
      align="center"
      justify="center"
      w="100%"
      bg={bgColor}
      position="fixed"
      bottom="0"
      left="0"
      zIndex={{ sm: "100", md: "docked" }}
      boxShadow="md"
      p="1rem"
      gap={2}
      direction={"row"}
    >
      <Text color={textColorSecondary} fontSize="lg" me="26px">
        Get Internal Information!
      </Text>

      <Link href="#/home/plan-a">
        <Button
          variant="outline"
          size="md"
          fontWeight="medium"
          mr={4}
          _focus={{ boxShadow: "none" }}
        >
          Get now
        </Button>
      </Link>
      <Key />
    </Flex>
  );
};

export default FooterAd;
