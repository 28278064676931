import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// Chakra imports
import { Box, Collapse, Flex, useColorModeValue } from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Footer from "components/footer/FooterAuth";
// Custom components
// Assets
import { FaBars, FaHome, FaPaperPlane, FaRocket } from "react-icons/fa";

import NavItem from "./shared/NavItem";

function HomeIllustration(props) {
  const { children } = props;

  // let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  const [isOpen, onToggle] = useState(false);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref && !ref.contains(event.target)) {
        onToggle(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onToggle]);
  const [selectedMenu, setSelectedMenu] = useState("Home");
  const navLinks = [
    {
      icon: FaHome,
      title: "Home",
      active: true,
      link: "top",
      external: "/home/home",
    },
    {
      icon: FaRocket,
      title: "Plans",
      active: false,
      link: "plans",
      external: "/home/home",
    },
    {
      icon: FaPaperPlane,
      title: "Contact",
      active: false,
      link: "contact",
      external: "/home/home",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        w="100%"
        px="4.5%"
        mt="30px"
        borderRadius="10px"
        justifyContent="end"
        direction="row"
        right={0}
        position="absolute"
        zIndex={123}
        ref={setRef}
      >
        <Box
          pos="absolute"
          visibility={isOpen ? "hidden" : "visible"}
          display={{ base: "block", md: "none", lg: "none", xl: "none" }}
        >
          <NavItem
            bg={"none"}
            key="menu"
            icon={FaBars}
            title=""
            active={selectedMenu}
            onClick={() => {
              onToggle(!isOpen);
            }}
          />
        </Box>
        <Flex
          display={{ base: "block", md: "none" }}
          borderRadius="10px"
          justifyContent="end"
          direction="row"
          bg={navbarBg}
          boxShadow={navbarShadow}
          backdropFilter={navbarBackdrop}
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <Collapse in={isOpen} animateOpacity>
            {navLinks.map((navItem) => (
              <NavItem
                key={navItem?.title}
                icon={navItem?.icon}
                title={navItem?.title}
                active={selectedMenu}
                link={navItem?.external}
                onClick={() => {
                  setSelectedMenu(navItem?.title);
                  onToggle(false);
                  const section = document.getElementById(navItem?.link);
                  section.scrollIntoView({ behavior: "smooth" });
                }}
              />
            ))}
          </Collapse>
        </Flex>
        <Flex
          visibility={{ base: "hidden", md: "visible" }}
          borderRadius="100px"
          justifyContent="end"
          direction="row"
          position="fixed"
          bg={navbarBg}
          boxShadow={navbarShadow}
          backdropFilter={navbarBackdrop}
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          {navLinks.map((navItem) => (
            <NavItem
              key={navItem?.title}
              icon={navItem?.icon}
              title={navItem?.title}
              active={selectedMenu}
              link={navItem?.external}
              onClick={() => {
                setSelectedMenu(navItem?.title);
                const section = document.getElementById(navItem?.link);
                section.scrollIntoView({ behavior: "smooth" });
              }}
            />
          ))}
        </Flex>
      </Flex>
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        maxW={{ lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ sm: "1rem", md: "20px", lg: "30px", xl: "70px" }}
        // ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}
// PROPS

HomeIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default HomeIllustration;
