/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  useColorModeValue
} from "@chakra-ui/react";
import Cart from "components/cart/Cart";
import { useEffect, useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
// Chakra imports
import "aos/dist/aos.css";
import illustration from "assets/img/auth/auth.png";
import CartCard from "components/card/CartCard";
import DefaultHome from "layouts/home/Plan";
import {
  getDataRealtime,
  getKeysRealtimeInArrayWithFlag,
} from "../../../utils/firebase";

function PlanA() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const [state, setState] = useState([]);
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [cart, setCart] = useState([]);
  const [mapLinks, setMapLinks] = useState({});
  const [matchedLinks, setMatchedLinks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getDataRealtime("States", "", setMapLinks);
    getKeysRealtimeInArrayWithFlag("colleges", "", (vals)=>{setState(vals[0]); setStatus(vals[1])}, setIsLoading);
    console.log(state);
  }, []);
  const handleSearch = (e) => {
    e.preventDefault();
    const matchingIndexes = [];
    state.forEach((item, index) => {
      if (item.toLowerCase().includes(search.toLowerCase())) {
        matchingIndexes.push(index);
      }
    });
    setMatchedLinks(matchingIndexes.map((index) => state[index]));
  };
  const addToCart = (item) => {
    if (!cart.includes(item)) setCart((prev) => [...prev, item]);
  };
  const deleteFromCart = (item) => {
    let tmp = [];
    cart.map((items) => {
      if (item !== items) {
        tmp.push(items);
      }
    });
    setCart(tmp);
  };
  // Chakra color mode
  const planA = {
    title: "Plan A",
    embed: "https://www.youtube.com/embed/4-6BrPSXSmU?controls=0",
    price: "Get Now",
    link: "#/home/plan-a",
    points: [
      {
        content: "College search and selection",
        positive: true,
      },
      {
        content: "Application support",
        positive: false,
      },
      {
        content: "Testing support",
        positive: false,
      },
      {
        content: "Financial aid and scholarship advice",
        positive: false,
      },
      {
        content: "Decision making",
        positive: false,
      },
    ],
  };
  return (
    <>
      <DefaultHome illustrationBackground={illustration} image={illustration}>
        {isLoading ? (
          <Flex
            w="100%"
            h="100vh"
            direction="column"
            justify={"center"}
            align="center"
          >
            <Spinner color={textColorPrimary} />
          </Flex>
        ) : (
          <>
            <form onSubmit={(e) => handleSearch(e)}>
              <InputGroup
                mt={{ sm: "10vh", md: "25vh" }}
                w={{ sm: "100%", md: "80%" }}
                mx={{ sm: "none", md: "10%" }}
              >
                <InputRightElement
                  children={
                    <IconButton
                      m="auto"
                      type="submit"
                      variant="link"
                      icon={<HiMagnifyingGlass color="gray.300" />}
                    />
                  }
                />
                <Input
                  bg={inputBg}
                  fontWeight="500"
                  focusBorderColor="#7551ff"
                  borderRadius="10rem"
                  type="text"
                  placeholder="Search..."
                  value={search}
                  color="white"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </form>
            <Cart
              deleteFromCart={deleteFromCart}
              items={cart}
              emptyCart={() => setCart([])}
            />

            <SimpleGrid
              id="about"
              columns={{ sm: "1", md: "3", lg: "3", xl: "3" }}
              gap="30px"
              px="4.5%"
              my={{ sm: "5vh", md: "25vh" }}
            >
              {matchedLinks && matchedLinks.length !== 0 && search.length !== 0
                ? matchedLinks.map((state, index) => (
                    <CartCard
                      deleteFromCart={deleteFromCart}
                      addToCart={addToCart}
                      key={index}
                      title={state}
                      mapLinks={mapLinks}
                      isAdded={cart.includes(state)}
                      isActive={status[state]?.isActive}
                    />
                  ))
                : state.map((state, index) => (
                    <CartCard
                      deleteFromCart={deleteFromCart}
                      addToCart={addToCart}
                      key={index}
                      title={state}
                      mapLinks={mapLinks}
                      isAdded={cart.includes(state)}
                      isActive={status[state]?.isActive}
                    />
                  ))}
            </SimpleGrid>
          </>
        )}
      </DefaultHome>
    </>
  );
}

export default PlanA;
