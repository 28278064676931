import {
  Box,
  Button,
  Text,
  useClipboard,
  useToast,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCollection } from "utils/firebase";

function OrderConfirmationPage() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const toast = useToast();
  const queryParams = new URLSearchParams(window.location.search);
  const razorpay_payment_id = queryParams.get("razorpay_payment_link_id");
  const status = queryParams.get("razorpay_payment_link_status");
  const [key, setKey] = useState("");
  const handleSubmit = (items) => {
    getCollection(
      "internal",
      (data) => {
        dispatch({ type: "stateChange", payload: data });
      },
      ["state", "in", items]
    );
  };
  useEffect(() => {
    getCollection(
      "plans",
      (value) => {
        const val = Object.values(value)[0];
        console.log(value);
        setKey(val?.key);
        handleSubmit(val?.items);
      },
      ["razorpay_id", "==", razorpay_payment_id]
    );
  });

  useEffect(() => {
    if (key !== "" && key) {
      // Function to download the text file
      const downloadTextFile = () => {
        const blob = new Blob([key], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "key.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      };

      downloadTextFile();
    }
  }, [key]);
  const { onCopy } = useClipboard(key);

  return (
    <Box m="auto" textAlign="center" p="5%">
      <Text fontWeight="bold" fontSize="4xl" textAlign="center" mb={4}>
        Order Confirmation
      </Text>
      <Text textAlign="center" mb={2}>
        Your order has been{" "}
        {status === "paid" ? "successfully processed" : "failed"}. Thank you for
        choosing us!
      </Text>
      <Box>
        <UnorderedList mb={4} w="50%" mx="auto">
          <ListItem textAlign="left" mb={2}>
            Please save this key for future reference. We've made it easy by
            automatically downloading a copy for you.
          </ListItem>
          <ListItem textAlign="left">
            If you lose this key, simply reach out to our support team, and
            we'll issue you a new one.
          </ListItem>
        </UnorderedList>
      </Box>
      <Text color="secondaryGray.600" mb={2} textAlign="center">
        Order ID: {razorpay_payment_id}
      </Text>
      <Box display="flex" gap="1rem" maxW="500px" alignItems="center" mx="auto">
        <Box
          mx="auto"
          display="flex"
          borderRadius="1rem"
          border="1px"
          px="1rem"
          textAlign="center"
          borderColor="secondaryGray.700"
        >
          <Text
            color="white"
            pr="4.5rem"
            fontSize="2xl"
            py="0.5rem"
            my="auto"
            pl="1rem"
            textAlign="center"
            lineHeight="1.7"
          >
            {key}
          </Text>
          <Button
            onClick={() => {
              onCopy();
              toast({
                title: "Copied!",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            }}
            my="auto"
            variant="ghost"
          >
            <MdContentCopy />
          </Button>
        </Box>
      </Box>
      <Button
        size="md"
        fontWeight="medium"
        mt="1rem"
        onClick={() => navigate.replace("/home/states")}
      >
        See internal details
      </Button>
    </Box>
  );
}

export default OrderConfirmationPage;
