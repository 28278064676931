// Chakra imports
import { Image, Link, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

// Assets
import bgMastercard from "assets/img/dashboards/freeCard.png";

export default function StateCard(props) {
  const textColorPrimary = useColorModeValue("white", "white");
  const { title, mapLinks = {}, ...rest } = props;

  // Chakra Color Mode
  return (
    <Card
      backgroundImage={bgMastercard}
      backgroundRepeat="no-repeat"
      bgSize="cover"
      alignSelf="center"
      justifyContent="center"
      align="center"
      bgPosition="10%"
      // mx="auto"
      p="20px"
      {...rest}
    >
      <Link href={`#/home/colleges/${title}`}>
        <Image
          // borderRadius="full"
          boxSize="250px"
          src={mapLinks.hasOwnProperty(title) ? mapLinks[title] : ""}
          alt="Accidental Doctor"
        />
        <Text
          fontSize="2xl"
          align={"center"}
          fontWeight="bold"
          color={textColorPrimary}
        >
          {title}
        </Text>
      </Link>
      {/* <Flex direction="column" color="white" h="100%" w="100%">
        <Flex justify="space-between" align="center" mb="37px">
          <Icon as={RiMastercardFill} w="48px" h="auto" color="gray.400" />
        </Flex>
        <Spacer /> */}
      {/* <Flex direction="column">
          <Box>
            <Text fontSize={{ sm: "xl", lg: "lg", xl: "xl" }} fontWeight="bold">
              {description}
            </Text>
          </Box>
          <Flex mt="14px">
            <Flex direction="column" me="34px">
              <Text fontSize="xs">VALID THRU</Text>
              <Text fontSize="sm" fontWeight="500"></Text>
            </Flex>
            <Flex direction="column">
              <Text fontSize="xs">CVV</Text>
              <Text fontSize="sm" fontWeight="500">
                {image}
              </Text>
            </Flex>
          </Flex>
        </Flex> */}
      {/* </Flex> */}
    </Card>
  );
}
