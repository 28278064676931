// Chakra imports
import { Text, Flex, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

export default function InfoCard(props) {
    const { title, value, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const bg = useColorModeValue("white", "navy.700");
    return (
        <Card bg={bg} {...rest}>
            <Flex>
                <Text fontWeight="500" color="gray.400" fontSize="md">
                    {title}:
                </Text>
                <Text color={textColorPrimary} fontWeight="500" fontSize="md" ml="4px">
                    {value}
                </Text>
            </Flex>
        </Card>
    );
}
