// Chakra imports
import { Center, IconButton, Image, Text, Tooltip } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { MdAdd, MdClose } from "react-icons/md";

// Assets
import bgMastercard from "assets/img/dashboards/Debit.png";
import bgBlackcard from "assets/img/dashboards/BGblack.png";
import { IoMdAlert } from "react-icons/io";

export default function CartCard(props) {
  const {
    title,
    addToCart,
    deleteFromCart,
    isAdded,
    isActive = true,
    mapLinks = {},
    ...rest
  } = props;
  // Chakra Color Mode
  return (
    <Center>
      <Card
        backgroundImage={isActive ? bgMastercard : bgBlackcard}
        backgroundRepeat="no-repeat"
        bgSize="cover"
        alignSelf="center"
        justifyContent="center"
        transition="opacity 0.2s"
        opacity="1"
        align="center"
        bgPosition="10%"
        mx="auto"
        p="20px"
        {...rest}
      >
        {isActive ? (
          isAdded ? (
            <Tooltip label="Remove">
              <IconButton
                borderRadius="full"
                color="red.500"
                bg="blackAlpha.400"
                fontSize="xl"
                variant="ghost"
                position="absolute"
                right="5%"
                top="5%"
                onClick={() => deleteFromCart(title)}
                icon={<MdClose />}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Add">
              <IconButton
                borderRadius="full"
                variant="ghost"
                fontSize="xl"
                bg="blackAlpha.400"
                position="absolute"
                right="5%"
                top="5%"
                onClick={() => addToCart(title)}
                icon={<MdAdd />}
              />
            </Tooltip>
          )
        ) : (
          <Tooltip label="Not Available">
            <IconButton
              borderRadius="full"
              color="red.500"
              bg="blackAlpha.400"
              fontSize="xl"
              variant="ghost"
              position="absolute"
              right="5%"
              top="5%"
              icon={<IoMdAlert />}
            />
          </Tooltip>
        )}
        <Center>
          <Image
            // borderRadius="full"
            boxSize="250px"
            src={mapLinks.hasOwnProperty(title) ? mapLinks[title] : ""}
            alt={title}
          />
        </Center>
        <Text fontSize="2xl" align={"center"} fontWeight="bold" color="white">
          {title}
        </Text>
      </Card>
    </Center>
  );
}
