/*eslint-disable*/
import { useState } from "react";
import {
  Link,
  useColorModeValue,
  Flex,
  IconButton,
  Tooltip,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tabs,
  TabList,
  Tab, Text,
  ModalCloseButton,
  TabPanels, OrderedList,
  TabPanel,
  useDisclosure,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { HiOutlineDocumentText } from "react-icons/hi";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
export default function Footer() {
  let textColor = useColorModeValue("gray.400", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };
  const modalColor = useColorModeValue("white", "navy.700");
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent bgColor={modalColor}>
          <ModalCloseButton color="red.400" size="lg" />
          <ModalHeader>TERMS & CONDITIONS</ModalHeader>
          <ModalBody>
            <Tabs
              variant="soft-rounded"
              colorScheme="blue"
              index={selectedTab}
              onChange={handleTabChange}
            >
              <TabList>
                <Tab>Privacy Policy</Tab>
                <Tab>Terms and Conditions</Tab>
                <Tab>Cancellation and Refund</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      The personal information we are collecting from website
                      users are:
                    </Text>
                    <OrderedList>
                      <ListItem>Name</ListItem>
                      <ListItem>Email Address</ListItem>
                      <ListItem>Phone Number</ListItem>
                      <ListItem>NEET Score</ListItem>
                      <ListItem>Alternate Phone Number</ListItem>
                    </OrderedList>
                    <Text my="1rem">
                      We collect information through a form present on our
                      website. This information is non-confidential and its
                      safety is highly secure as it is privatized in our site
                      backend. It is only accessible to our counseling team,
                      which is secured by authentication, known only to our team
                      members. This information is needed to determine the
                      candidate's eligibility for counseling and to suggest the
                      best possible state under their budget for successful
                      completion of the counseling procedure. For example, we
                      need to know the candidate's NEET score to suggest the
                      best college under the best budget as every state has
                      different medical colleges with different budgets.
                    </Text>
                    <Text>
                      As this information is non-confidential, there is no
                      negative effect of sharing it with a 3rd party. However,
                      we highly secure this user data with the methods mentioned
                      in the above paragraph, which are confined to our team
                      members only.
                    </Text>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Contact information:
                    </Text>
                    <UnorderedList>
                      <ListItem>
                        Address: Basdiliya road Madhukarpur Siddharth Nagar
                        (UP), 272207
                      </ListItem>
                      <ListItem>Phone: +91-7078799044</ListItem>
                      <ListItem>Email: accidentaldoctorr@gmail.com</ListItem>
                    </UnorderedList>
                  </Box>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Effective date for policy:
                    </Text>
                    <Text>
                      The policy is effective from the time the NEET UG
                      counselling begins to the time it ends.the flexibility of
                      policy can be changed with the needs at anytime by the
                      admin. Refund for our services for our active PLANS is
                      initiated as per we mentioned in our refunds and
                      cancellation policy
                    </Text>
                  </Box>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Limitation of liability:
                    </Text>
                    <Text>
                      As a provider of NEET UG counseling services, it is
                      important to have a limitation of liability clause in your
                      terms and conditions to protect your business from any
                      unforeseen circumstances. The following clause can be
                      added to your terms and conditions:
                    </Text>
                    <UnorderedList>
                      <ListItem>
                        Our liability for any and all claims arising out of or
                        in connection with the services we provide, including
                        NEET UG counseling services, is limited to the amount
                        paid by you for such services.
                      </ListItem>
                      <ListItem>
                        We are not liable for any indirect, special, incidental,
                        or consequential damages arising out of or in connection
                        with the services we provide, including NEET UG
                        counseling services. This includes, but is not limited
                        to, loss of profits, loss of business, or loss of data.
                      </ListItem>
                      <ListItem>
                        We make no representations or warranties regarding the
                        accuracy, reliability, or completeness of the
                        information provided through our NEET UG counseling
                        services. We cannot guarantee that the information
                        provided will lead to successful college admission or
                        any other specific outcome.
                      </ListItem>
                      <ListItem>
                        In the event that any limitation or exclusion of
                        liability is found to be unenforceable, our liability
                        for any and all claims shall be limited to the fullest
                        extent permitted by law.
                      </ListItem>
                      <ListItem>
                        By using our NEET UG counseling services, you
                        acknowledge and agree to the limitations of liability
                        set forth in these terms and conditions.
                      </ListItem>
                      <ListItem>
                        Please note that this limitation of liability clause
                        does not limit your liability for any gross negligence,
                        willful misconduct, or intentional wrongdoing.
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Disclaimer of warranties:
                    </Text>
                    <Text>
                      We do not provide any warranties for our services. We understand that the college admission process is fully online and depends on the number of candidates enrolling for the counselling process. The higher the number of candidates, the higher the cutoff. We offer refunds for our Plan B and Plan C options (for more details, please refer to our refund and cancellation page)
                    </Text>
                  </Box>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Rules of conduct:
                    </Text>
                    <Text>
                      {" "}
                      Our counseling website is committed to providing a safe
                      and respectful environment for all users. To ensure that
                      our platform remains a positive and helpful resource, we
                      require all users to abide by the following rules of
                      conduct:
                    </Text>

                    <OrderedList>
                      <ListItem>
                        Be respectful: Treat others with respect and kindness.
                        Do not use offensive language or engage in personal
                        attacks.
                      </ListItem>
                      <ListItem>
                        Maintain confidentiality: Respect the privacy and
                        confidentiality of other users. Do not share personal
                        information or details of counseling sessions with
                        anyone outside of the platform.
                      </ListItem>
                      <ListItem>
                        Follow professional standards: All counselors on our
                        platform are licensed professionals and are held to
                        professional standards of conduct. Users are expected to
                        behave in a professional and ethical manner during
                        counseling sessions.
                      </ListItem>
                      <ListItem>
                        Be honest: Provide accurate and honest information about
                        yourself and your situation. Do not misrepresent
                        yourself or your circumstances.
                      </ListItem>
                      <ListItem>
                        Follow the law: Do not engage in any activity that is
                        illegal or violates any local, state, or federal laws.
                      </ListItem>
                      <ListItem>
                        Respect intellectual property: Do not copy or distribute
                        any content on our platform without permission from the
                        owner.
                      </ListItem>
                      <ListItem>
                        Use the platform appropriately: Our platform is designed
                        for counseling purposes only. Do not use it for any
                        other purpose, such as soliciting business or promoting
                        products or services.
                      </ListItem>
                      <ListItem>
                        Report any violations: If you witness or experience any
                        violations of these rules of conduct, please report them
                        to our team immediately.
                      </ListItem>
                    </OrderedList>
                    <Text mt="1rem" fontSize="2xl" casing="uppercase">
                      Consequences for Violating Rules of Conduct:
                    </Text>
                    <Text>
                      Any user who violates these rules of conduct may be
                      subject to consequences, including but not limited to,
                      warnings, suspension or termination of account, and legal
                      action. By using our counseling website, you agree to
                      abide by these rules of conduct. We reserve the right to
                      update or modify these rules at any time.
                    </Text>
                  </Box>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      User restrictions:
                    </Text>
                    <OrderedList>
                      <ListItem>
                        Age Restrictions: The website should be restricted to
                        users above a certain age, such as 18 years old or
                        older. Make sure to clearly state this age requirement
                        on your website and require users to confirm their age
                        before accessing the platform.
                      </ListItem>
                      <ListItem>
                        Personal Information: Users should be prohibited from
                        sharing personal information such as their full name,
                        address, phone number, or email address. It's important
                        to protect the privacy of your users and to ensure that
                        their personal information is not shared or used
                        inappropriately.
                      </ListItem>
                      <ListItem>
                        Inappropriate Content: Users should not be allowed to
                        post or share any content that is inappropriate,
                        offensive, or harmful to others. This includes content
                        that is discriminatory, harassing, or defamatory. Ensure
                        that users are aware of this policy and provide a
                        mechanism to report any inappropriate content.
                      </ListItem>
                      <ListItem>
                        Professional Conduct: Users should behave in a
                        professional and respectful manner when using the
                        platform. They should not engage in any behavior that
                        could be perceived as unprofessional or inappropriate.
                        This includes using inappropriate language or making
                        disrespectful comments towards other users.
                      </ListItem>
                      <ListItem>
                        Prohibited Activities: Users should be prohibited from
                        engaging in any activities that are illegal or that
                        could harm others. This includes activities such as
                        hacking, spamming, or distributing malicious software.
                      </ListItem>
                      <ListItem>
                        User Responsibility: Users should be held responsible
                        for their actions on the platform. Make sure to clearly
                        state the consequences of violating the user
                        restrictions policy, such as account suspension or
                        termination.
                      </ListItem>
                      <ListItem>
                        Confidentiality: It's important to maintain
                        confidentiality between users and counsellors on the
                        platform. Ensure that users are aware of this policy and
                        provide a mechanism to report any breach of
                        confidentiality.
                      </ListItem>
                    </OrderedList>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Cancellation Policy:
                    </Text>
                    <UnorderedList>
                      <ListItem>
                        If you wish to cancel your subscription to our services,
                        you may do so at any time. However, please note that we
                        do not provide refunds for PLAN A subscriptions once the
                        payment has been processed.
                      </ListItem>
                      <ListItem>
                        For PLAN B and PLAN C subscriptions, you may cancel at
                        any time, but we will only provide a full refund of the
                        subscription fee, excluding the token amount, if you
                        have not been able to secure a college admission through
                        our services.
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <Box p="4">
                    <Text fontSize="2xl" casing="uppercase">
                      Refund Policy:
                    </Text>
                    <UnorderedList>
                      <ListItem>
                        We understand that you may have concerns about the
                        quality of our services. If you have subscribed to PLAN
                        B or PLAN C and have not been able to secure a college
                        admission, we will provide you with a full refund,
                        excluding the token amount.
                      </ListItem>
                      <ListItem>
                        However, if you have subscribed to PLAN A, we regret to
                        inform you that we do not offer any refunds once the
                        payment has been processed.
                      </ListItem>
                      <ListItem>
                        Please note that we will process your refund within 14
                        business days of receiving your cancellation request.
                        The refund will be made to the original payment method
                        used at the time of purchase.
                      </ListItem>
                      <ListItem>
                        We reserve the right to modify this policy at any time
                        without prior notice. If you have any questions or
                        concerns regarding our cancellation and refund policy,
                        please feel free to contact us at
                        accidentaldoctorr@gmail.com
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <Box p="4">
                    <Text>
                      Thank you for choosing our services. We appreciate your
                      business
                    </Text>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex as="footer" justify="center" align="center" gap="1rem" py="2rem">
        <Tooltip label="Facebook">
          <IconButton
            as={Link}
            target="_blank"
            href="https://www.facebook.com/Accidental-doctor-101994742360216/"
            aria-label="Facebook"
            icon={<FaFacebook />}
            size="lg"
          />
        </Tooltip>
        <Tooltip label="Instagram">
          <IconButton
            as={Link}
            target="_blank"
            href="https://instagram.com/accidentaldoctor"
            aria-label="Instagram"
            icon={<FaInstagram />}
            size="lg"
          />
        </Tooltip>
        <Tooltip label="YouTube">
          <IconButton
            as={Link}
            target="_blank"
            href="https://youtube.com/@AccidentalDoctor"
            aria-label="YouTube"
            icon={<FaYoutube />}
            size="lg"
          />
        </Tooltip>

        <Button
          leftIcon={<HiOutlineDocumentText />}
          target="_blank"
          onClick={onOpen}
          // href="https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/pdf%2FTerms%20and%20conditions.pdf?alt=media&token=421f57f1-c875-40b0-92b6-0c535c7e7bd7"
          size="lg"
          display={{ sm: "none", md: "inline-flex" }}
        >
          TERMS & CONDITIONS
        </Button>
        <IconButton
          // as={Link}
          target="_blank"
          onClick={onOpen}
          // href="https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/pdf%2FTerms%20and%20conditions.pdf?alt=media&token=421f57f1-c875-40b0-92b6-0c535c7e7bd7"
          aria-label="Terms & Conditions"
          icon={<HiOutlineDocumentText />}
          size="lg"
          display={{ sm: "inline-flex", md: "none" }}
        />
      </Flex>
    </>
  );
}
